import React, { useEffect, useState } from 'react';
import styles from './BillingSettings.module.scss';
import {
    DataList,
    DataListTypes,
    ListItem,
} from '../../../components/dataList/DataList';
import {
    setAuthNetModalOpen,
    setAutopayAccount,
    setAutopayCustomerId,
    setAutopayLocationId,
    setAutopayModalOpen,
    setAutopayStatus,
    setNavilineModalOpen,
    setOrganizationId,
    setUserId,
    useAppStateBillingSettingsSelector,
} from './billingSettingsSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    addPaymentInformation,
    loadPaymentInformation,
    loadPaymentProfile,
    removePaymentInformation,
    removePaymentMethod,
} from './billingSettingsActionCreators';
import { AddCardModal } from './AddCardModal';
import { AutopayConfigModal } from './AutopayConfigModal/AutopayConfigModal';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';
import { MessageModal } from '../../../components/statusModal/MessageModal';
import { axios } from '../../../services/axiosService';
import { useFeatureFlag } from '../../featureFlags/FeatureFlag';
import { NavilineAddModal } from './NavilineAddModal';
import { useHistory } from 'react-router-dom';

export interface BillingSettingsProps {
    UserId?: string;
    OrganizationId?: string;
}

// Component for displaying both the user's naviline accounts and the stored payments
export function BillingSettings({
    UserId,
    OrganizationId,
}: BillingSettingsProps) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const [autopayAcctEnabled, setAutopayAcctEnabled] = useState(false);
    const [autopayAcctCard, setAutopayAcctCard] = useState();
    const [autopayId, setAutopayId] = useState();
    const [deletedCardId, setDeletedCardId] = useState('');
    const [deleteCardWarningOpen, setDeleteCardWarningOpen] = useState(false);
    const [deleteAutopayModalOpen, setDeleteAutopayModalOpen] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState<ListItem[]>([]);
    const coreFF = useFeatureFlag('EnableCORE');

    const {
        navilineModalOpen,
        navilineListData,
        authNetModalOpen,
        paymentProfile,
        autopayModalOpen,
        coreStoredPayments,
        autopayCustomerId,
        autopayLocationId,
        loadingStoredPayments,
    } = useAppStateBillingSettingsSelector((state) => state);

    useEffect(() => {
        dispatch(setUserId(UserId));
        dispatch(setOrganizationId(OrganizationId));
        dispatch(loadPaymentInformation());
        dispatch(loadPaymentProfile(UserId, coreFF?.enabled));
    }, [dispatch, UserId, OrganizationId, coreFF]);

    useEffect(() => {
        if (coreFF) {
            let resultingPaymentMethods;
            if (coreFF?.enabled) {
                resultingPaymentMethods = coreStoredPayments
                    ? coreStoredPayments.map((paymentMethod) => {
                          return {
                              Id: paymentMethod.id,
                              Label: `${
                                  paymentMethod.name
                                      ? paymentMethod.name + ' - '
                                      : ''
                              } ${paymentMethod.card_type} - ${
                                  paymentMethod.accountnum
                              }`,
                              Data: paymentMethod,
                              Tag: undefined,
                          };
                      })
                    : [];
            } else {
                resultingPaymentMethods = paymentProfile
                    ? paymentProfile.map((profile: any) => {
                          return {
                              Id: profile.Id,
                              Label: `${profile.Type} - ${
                                  profile.MaskedAccountNumber
                              }${
                                  profile.ProfileDescription
                                      ? ` (${profile.ProfileDescription})`
                                      : ''
                              }`,
                              Data: profile,
                              Tag: undefined,
                          };
                      })
                    : [];
            }
            setPaymentMethods(resultingPaymentMethods);
        }
    }, [coreFF, coreStoredPayments, paymentProfile, loadingStoredPayments]);

    const removeCard = async (id: string) => {
        const result = (await axios.get(`/payment-methods/${id}`)).data;
        setDeletedCardId(id);
        setDeleteCardWarningOpen(true);
        // Show warning if card is attached to autopay
        if (result) {
            dispatch(
                openMessageModal(
                    'warning-delete-saved-card-with-autopay',
                    'warning',
                ),
            );
        } else {
            dispatch(openMessageModal('warning-delete-saved-card', 'warning'));
        }
    };

    return (
        <div>
            <h1>{t('Billing.BillingSettings')}</h1>
            <p className={styles.achDisclaimer}>
                {t('Billing.BillingSettingsDesc')}
            </p>
            <h1>{t('Billing.ManageCreditCardAutopay')}</h1>

            <NavilineAddModal
                modalOpen={navilineModalOpen}
                onClose={(accountNumber, streetNumber) =>
                    accountNumber && streetNumber
                        ? dispatch(
                              addPaymentInformation(
                                  accountNumber,
                                  streetNumber,
                                  navilineListData,
                              ),
                          )
                        : dispatch(setNavilineModalOpen(false))
                }
            />
            <AddCardModal modalOpen={authNetModalOpen} />
            <AutopayConfigModal
                modalOpen={autopayModalOpen}
                enabled={autopayAcctEnabled}
                savedCard={autopayAcctCard}
                id={autopayId}
            />
            {/* Remove naviline account modal */}
            <MessageModal
                modalOpen={deleteAutopayModalOpen}
                onConfirmationButtonClick={() => {
                    dispatch(
                        removePaymentInformation(
                            autopayCustomerId,
                            autopayLocationId,
                        ),
                    );
                    setDeleteAutopayModalOpen(false);
                }}
                onCloseButtonClick={() => {
                    setDeleteAutopayModalOpen(false);
                }}
            />
            {/* Delete saved payment modal */}
            <MessageModal
                modalOpen={deleteCardWarningOpen}
                onConfirmationButtonClick={() => {
                    setDeleteCardWarningOpen(false);
                    dispatch(
                        removePaymentMethod(
                            deletedCardId,
                            coreFF?.enabled,
                            UserId!,
                        ),
                    );
                }}
                onCloseButtonClick={() => {
                    setDeleteCardWarningOpen(false);
                }}
            />

            {/* Autopay and bills */}
            <DataList
                title={t('Billing.WaterBillingAccount', {
                    count: navilineListData.length,
                })}
                type={DataListTypes.navilineAccounts}
                items={navilineListData}
                addCallback={() => dispatch(setNavilineModalOpen(true))}
                deleteCallback={(_id, data) => {
                    setDeleteAutopayModalOpen(true);
                    dispatch(
                        openMessageModal('warning-remove-autopay', 'warning'),
                    );
                    dispatch(setAutopayCustomerId(data.CustomerId));
                    dispatch(setAutopayLocationId(data.LocationId));
                }}
                tagClickCallback={(_id, data) => {
                    console.log(_id, data);
                    setAutopayAcctEnabled(data.Autopay?.AutopayEnabled);
                    setAutopayAcctCard(data.Autopay?.PaymentProfileId);
                    setAutopayId(data.Autopay?.AutopayId);
                    dispatch(setAutopayAccount(_id));
                    dispatch(setAutopayCustomerId(data.CustomerId));
                    dispatch(setAutopayLocationId(data.LocationId));
                    dispatch(setAutopayStatus(data.Autopay.AutopayStatus));
                    if (data.Autopay.AutopayStatus === 'C') {
                        setAutopayAcctEnabled(true);
                    }
                    dispatch(setAutopayModalOpen(true));
                }}
            />

            {/* Stored payments */}
            <DataList
                title={t('Billing.StoredPaymentMethod', {
                    count: paymentMethods.length,
                })}
                type={DataListTypes.savedPaymentMethods}
                items={paymentMethods}
                addCallback={() =>
                    coreFF?.enabled
                        ? history.push('/add-stored-payment')
                        : dispatch(setAuthNetModalOpen(true))
                }
                deleteCallback={(_id, data) => removeCard(_id)}
            />
        </div>
    );
}
