import React, { useEffect } from 'react';
import { Dropdown, DropdownOptions } from '../../../../components/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppStateReportSelector, setCategoryCanSubmit, setLRSRequestSize, setLRSRequestType } from '../reportSlice';

export const LRSGarbageRecyclingToter = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useAppStateReportSelector(state => state.lrsServiceRequest);

    const toterTypeOptions: DropdownOptions[] = [
        {
            value: "LRS General Information",
            key: "LRS General Information",
            text: t('Report.LRSGeneralInformation'),
        },
        {
            value: "LRS Performance Complaint",
            key: "LRS Performance Complaint",
            text: t('Report.LRSPerformanceComplaint'),
        },
        {
            value: "Missed Collection",
            key: "Missed Collection",
            text: t('Report.LRSMissedCollection'),
        },
        {
            value: "Organics",
            key: "Organics",
            text: t('Report.LRSOrganics'),
        },
        {
            value: "Request for Repair/Replacement",
            key: "Request for Repair/Replacement",
            text: t('Report.LRSRequestForRepairReplacement'),
        },
    ];

    const toterSizeOptions: DropdownOptions[] = [
        {
            value: "35 Gallon",
            key: "35 Gallon",
            text: `35 ${t('Report.Gallon')}`,
        },
        {
            value: "64 Gallon",
            key: "64 Gallon",
            text: `64 ${t('Report.Gallon')}`,
        },
    ];

    useEffect(() => {
        if (state.size && state.type) {
            dispatch(setCategoryCanSubmit(true));
        }
        else {
            dispatch(setCategoryCanSubmit(false));
        }
    }, [dispatch, state.size, state.type]);

    return (
        <>
            {t('Report.WhatIsTheRequestedContainerSize')}
            <Dropdown
                placeholder={t('Report.WhatIsTheRequestedContainerSize')}
                options={toterSizeOptions}
                value={state.size}
                onChange={(e: any, data: any) => dispatch(setLRSRequestSize(data.value))}
            />
            {t('Report.WhatIsTheRequestedServiceType')}
            <Dropdown
                placeholder={t('Report.WhatIsTheRequestedServiceType')}
                options={toterTypeOptions}
                value={state.type}
                onChange={(e: any, data: any) => dispatch(setLRSRequestType(data.value))}
            />
        </>
    )
}
